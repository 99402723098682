<template>
  <div>

    <v-dialog
        v-model="newsLetter"
        max-width="976"
    >
      <div class="sm:tw-grid sm:tw-grid-cols-2 tw-max-w-9xl tw-mx-auto tw-px-0 tw-relative ">
        <div class="tw-hidden sm:tw-grid">
          <img src="@/assets/img/newsletters.jpg" alt="NewsLetter">
        </div>
        <!-- ... -->

        <div class="tw-bg-red-100 tw-px-5 tw-py-7">

          <div>
            <form ref="form" novalidate="false" @submit.prevent="handleSubmit" @change="validateForm()" method="POST">
              <div class="tw-flex tw-justify-end"><XIcon class="tw-cursor-pointer tw-text-gray-n3" @click="close"/></div>
              <div class="tw-text-black">
                <h2 class="tw-text-2rem tw-font-serif tw-mb-8">Join our community</h2>
                <p class="tw-text-sm tw-font-bold tw-mb-3">Be the FIRST to know!</p>
                <p class="tw-text-sm tw-leading-relaxed">Sign up to receive email updates on new product announcements, business tips, wedding planning ideas & more.</p>
              </div>
              <div class="sm:tw-grid sm:tw-grid-cols-2 tw-gap-4 tw-mt-5">
                <div>
                  <ml-label required for="first_name">First Name</ml-label>
                  <div class="tw-mt-2">
                    <ml-input-text v-model="item.firstname" id="first_name" name="first_name" required/>
                    <ml-error-msg :msg="errors['first_name']"/>
                  </div>
                </div>
                <div class="sm:tw-mt-0 tw-mt-3">
                  <ml-label required for="last_name">Last Name</ml-label>
                  <div class="tw-mt-2">
                    <ml-input-text v-model="item.lastname" id="last_name" name="last_name" required/>
                    <ml-error-msg :msg="errors['last_name']"/>
                  </div>
                </div>
                <div class="sm:tw-mt-0 tw-mt-3">
                  <ml-label required for="zip">Zip code</ml-label>
                  <div class="tw-mt-2">
                    <ml-input-text v-model="item.zip" id="zip" name="zip" required/>
                    <ml-error-msg :msg="errors['zip']"/>
                  </div>
                </div>
                <div class="sm:tw-mt-0 tw-mt-3">
                  <ml-label required for="email">Email</ml-label>
                  <div class="tw-mt-2">
                    <ml-input-text v-model="item.email" id="email" name="email" required/>
                    <ml-error-msg :msg="errors['email']"/>
                  </div>
                </div>
              </div>
              <div class="tw-flex tw-gap-x-4 tw-mt-3">
                <label class="tw-mb-0 tw-relative tw-flex tw-items-center">
                  <input v-model.number="item.type" type="radio" name="project-type" value="0"
                         class="tw-sr-only" aria-labelledby="project-type-0-label"
                         aria-describedby="project-type-0-description-0">

                  <svg v-if="item.type === 0" width="16" height="16" viewBox="-1 -1 18 18" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8Z"
                        fill="#E1E1E1"/>
                    <circle cx="8" cy="8" r="6" fill="#E14D4D"/>
                    <path
                        d="M8 15C4.13401 15 1 11.866 1 8H-1C-1 12.9706 3.02944 17 8 17V15ZM15 8C15 11.866 11.866 15 8 15V17C12.9706 17 17 12.9706 17 8H15ZM8 1C11.866 1 15 4.13401 15 8H17C17 3.02944 12.9706 -1 8 -1V1ZM8 -1C3.02944 -1 -1 3.02944 -1 8H1C1 4.13401 4.13401 1 8 1V-1Z"
                        fill="#E14D4D"/>
                  </svg>
                  <span v-else class="tw-h-4 tw-w-4 tw-border-1 tw-border-gray-n1 tw-rounded-full tw-bg-white">
              </span>
                  <span id="project-type-1-label" class="ml-3 tw-block tw-text-base" :class="{'tw-font-semibold': item.type === 0}">
                Couple
              </span>
                </label>

                <label class="tw-mb-0 tw-relative tw-flex tw-items-center">
                  <input v-model.number="item.type" type="radio" name="project-type" value="1"
                         class="tw-sr-only" aria-labelledby="project-type-1-label"
                         aria-describedby="project-type-1-description-0 project-type-1-description-1">
                  <svg v-if="item.type === 1" width="16" height="16" viewBox="-1 -1 18 18" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8Z"
                        fill="#E1E1E1"/>
                    <circle cx="8" cy="8" r="6" fill="#E14D4D"/>
                    <path
                        d="M8 15C4.13401 15 1 11.866 1 8H-1C-1 12.9706 3.02944 17 8 17V15ZM15 8C15 11.866 11.866 15 8 15V17C12.9706 17 17 12.9706 17 8H15ZM8 1C11.866 1 15 4.13401 15 8H17C17 3.02944 12.9706 -1 8 -1V1ZM8 -1C3.02944 -1 -1 3.02944 -1 8H1C1 4.13401 4.13401 1 8 1V-1Z"
                        fill="#E14D4D"/>
                  </svg>
                  <span v-else class="tw-h-4 tw-w-4 tw-border-1 tw-border-gray-n1 tw-rounded-full tw-bg-white">
              </span>
                  <span id="project-type-1-label" class="ml-3 tw-block tw-text-base" :class="{'tw-font-semibold': item.type === 1}">
                Vendor
              </span>
                </label>
              </div>
              <ml-error-msg :msg="errors['type']"/>
              <div class="tw-justify-center">
                <ml-alert v-if="errors.length" class="tw-mb-2 tw-mt-2">
                  <template v-slot:title>
                  </template>
                  <template v-slot:msg>
                    {{errors}}
                  </template>
                </ml-alert>


                <div v-if="message" class="tw-text-black tw-justify-center tw-mt-6">
                  <h2 class="tw-text-2rem tw-font-serif tw-mb-8"><span>{{message}}</span></h2>
                </div>
                <button v-else type="submit"
                        :disabled="loading"
                        class="tw-mt-6 tw-flex tw-justify-center tw-w-full tw-px-6 tw-py-4 tw-text-base tw-font-medium tw-rounded-xl tw-text-white tw-bg-red focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-gray-n4">
                  Subscribe
                </button>

              </div>
            </form>
          </div>

        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { XIcon } from 'vue-feather-icons'
import MlLabel from "@/components/layout/form/MlLabel";
import MlErrorMsg from "@/components/layout/form/MlErrorMsg";
import MlInputText from "@/components/layout/form/MlInputText";
import MlAlert from "@/components/layout/MlAlert";


export default {
  name: 'subscription',
  props: {
    openModal: {
      default: false,
      type: Boolean
    }
  },
  components:{
    XIcon,MlLabel,MlErrorMsg,MlInputText,MlAlert
  },
  data() {
    return {
      newsLetter: false,
      loading: false,
      item: {
        firstname: null,
        lastname: null,
        email: null,
        zip: null,
        type: null
      },
      errors: [],
      message: null,
      valid: false
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },
  mounted() {
    this.startNewsLetter(this.openModal);
  },
  methods: {
    startNewsLetter(openModal){

      if(openModal) this.newsLetter = true;

      //localStorage.setItem('newsLetter',null);

      if(!this.check){

        let timeout = 3000;
        let ls = JSON.parse(localStorage.getItem('newsLetter'));
        //console.log("localStorage existing",ls);

        if(ls){
          // Reset storage
          let haveVersion = 'version' in ls;
          //console.log("haveVersion",haveVersion);
          if(!haveVersion){
            //console.log("reset storage");
            localStorage.setItem('newsLetter',null);
          }else{
            //console.log("Version",ls.version);
            if(ls.version != "1"){
              // Version control
              //console.log("reset storage");
              localStorage.setItem('newsLetter',null);
            }
          }
          timeout = timeout * parseInt(ls.number);
          //console.log("timeout",timeout);
        }
        setTimeout(function() {

          let data = {};
          //let ls = JSON.parse(localStorage.getItem('newsLetter'));

          if(ls){
            //console.log("ls",ls);
            const storageDay = new Date(ls.date);
            const x = new Date(storageDay.setDate(storageDay.getDate()+(2*ls.number)));
            const today = new Date();
            //console.log("storageDay date:",storageDay);
            //console.log("x date:",x);
            //console.log("today date:",today);
            if(x <= today){
              console.log("Open Newsletter");
              this.newsLetter = true;
              data = {
                version: "1",
                number : 1+ls.number,
                date : today
              }
              localStorage.setItem('newsLetter',JSON.stringify(data));
            }
          }else{

            this.newsLetter = true;
            data = {
              version: "1",
              number : 1,
              date : new Date()
            }
            localStorage.setItem('newsLetter',JSON.stringify(data));
            //console.log("localStorage new",data);
          }

        }.bind(this), timeout);

      }
    },
    close(){
      this.clear();
      this.$emit('close', {});
      this.newsLetter = !this.newsLetter;
    },
    clear(){
      this.item.firstname = null;
      this.item.lastname = null;
      this.item.zip = null;
      this.item.email = null;
      this.item.null = null;
      this.errors = [];
      this.message = null;
    },
    validateForm(){
      this.errors = [];
      let label = '';

      if (!((this.item.type == 0) || (this.item.type == 1))){
        label = "Type required.";
        this.errors.push(label);
        this.errors["type"] = label;
      }

      if (!this.item.firstname) {
        label = "First Name required.";
        this.errors.push(label);
        this.errors["first_name"] = label;
      }

      if (!this.item.lastname) {
        label = "Last Name required.";
        this.errors.push(label);
        this.errors["last_name"] = label;
      }

      if (!this.item.zip) {
        label = "Zip code required.";
        this.errors.push(label);
        this.errors["zip"] = label;
      }

      if (!this.item.email) {
        label = "Email Required";
        this.errors.push(label);
        this.errors["email"] = label;
      } else if (!this.validEmail(this.item.email)) {
        label = "Valid Email Required";
        this.errors.push(label);
        this.errors["email"] = label;
      }

      if (!this.errors.length) {
        this.valid = true;
        //console.log("Form valid",this.valid);
        return true;
      }

      this.valid = false;
      //console.log("Form valid",this.valid);
      return false;

    },
    validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    handleSubmit(){
      if(this.validateForm()) this.submitSubscription();
    },
    submitSubscription(){

      this.loading = true;
      this.$http.post('/api/subscribe',this.item)
          .then(({data})  => {
            this.$toast.success(data.message, "Success");
            this.message = data.message;
          })
          .catch((error) => {
            //console.log(error);
            this.$toast.warning(error.data.message, "Warning");
            this.errors = error.data.errors;
            this.loading = false;
          })
          .finally(() =>
              this.loding = false
          )

    }
  }
};
</script>


