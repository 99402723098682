<template>
  <div>

    <SubscriptionForm :openModal="true"></SubscriptionForm>

  </div>
</template>

<script>



import SubscriptionForm from '@/components/SubscriptionForm.vue'

export default {
  components: {
    SubscriptionForm: SubscriptionForm
  },
  methods:{
  }
}
</script>
